import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet"; 
import './tearms.css';
export default function SecurityAdvisories() {
  return (
    <div className="container_roots">
<Helmet>
  <title>Disclaimer | CallQR</title>
  <meta name="description" content="Read the official disclaimer of CallQR regarding the usage, liability, and accuracy of information on our QR code generator platform." />
  <meta name="keywords" content="CallQR Disclaimer, QR Code Terms, QR Code Generator Disclaimer, Liability Notice, Information Accuracy, CallQR Terms" />
  <meta name="robots" content="noindex, nofollow" />
  <meta name="author" content="callqr.com" />

  {/* Open Graph */}
  <meta property="og:title" content="Disclaimer | CallQR" />
  <meta property="og:description" content="Understand the legal disclaimers related to using the CallQR QR code platform. Read our official notice." />
  <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
  <meta property="og:url" content="https://callqr.com/disclaimer" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CallQR" />

  {/* Twitter/X */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Disclaimer | CallQR" />
  <meta name="twitter:description" content="Learn more about our disclaimer and the terms under which CallQR operates." />
  <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
  <meta name="twitter:site" content="@CALLQR_" />
  <meta name="twitter:url" content="https://x.com/CALLQR_" />

  {/* Optional - Prevent indexing if it's a legal notice page */}
  <meta name="google-play-app" content="app-id=callqr.com" />
</Helmet>

      <div className="container_size">
      <h2>Recent Security Advisories</h2>
      <section>
            <article>
                <h2>Security Advisory #2025-001</h2>
                <p><strong>Issue:</strong> Potential vulnerability in QR code scanning mechanism.</p>
                <p><strong>Impact:</strong> Could allow unauthorized data access.</p>
                <p><strong>Resolution:</strong> Patch released in version 1.2.3.</p>
            </article>
            <article>
                <h2>Security Advisory #2025-002</h2>
                <p><strong>Issue:</strong> Weak encryption in data transmission.</p>
                <p><strong>Impact:</strong> Data interception risk.</p>
                <p><strong>Resolution:</strong> Upgraded encryption standards in version 1.3.0.</p>
            </article>
        </section>
        
        <section id="recommendations">
            <h2>Security Recommendations</h2>
            <ul>
                <li>Always update to the latest version of CallQR.</li>
                <li>Do not scan QR codes from unknown or untrusted sources.</li>
                <li>Enable two-factor authentication for added security.</li>
                <li>Use secure networks when scanning QR codes.</li>
            </ul>
        </section>
    <p><strong>Last Updated:</strong> 01 April 2025</p>
    </div>
</div>
    );
}
