import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { Helmet } from "react-helmet";
import { db } from "../firebase";
import './order.css'; 
import { BsCartPlus } from "react-icons/bs"; 

export default function Order() {
  const navigate = useNavigate();
  const [isOn, setIsOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [TitleValue, setTitleValue] = useState("");
  const [PriceValue, setPriceValue] = useState("0");
  const [ValidityValue, setValidityValue] = useState("");
  const [DescriptionValue, setDescriptionValue] = useState("");
  const [productPhotos, setProductPhotos] = useState([]);

  const [searchParams] = useSearchParams();
  const puid = searchParams.get("puid");
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (!puid) return;

    const fetchProduct = async () => {
      try {
        const docRef = doc(db, "products", puid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setTitleValue(data.title || "No Title");
          setPriceValue(data.price || "0");
          if(data.validity>365)
          { setValidityValue(
            data.validity && data.validity_liable
              ? `Validity: ${data.validity/365}  Year`
              : "No validity info"
          );}else{
          setValidityValue(
            data.validity && data.validity_liable
              ? `Validity: ${data.validity} ${data.validity_liable}`
              : "No validity info"
          );
        }
          setDescriptionValue(data.description || "No description available");
          document.title = `${data.title} | Purchase Order`;
        } else {
          setDescriptionValue("No product data found");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
        setDescriptionValue("Error loading product details");
      }
    };

    fetchProduct();
  }, [puid]);

  useEffect(() => {
    if (!puid) return;

    const fetchPhotos = async () => {
      try {
        const photosQuery = query(
          collection(db, "slideshow"),
          where("puid", "==", puid)
        );
        const snapshot = await getDocs(photosQuery);
        const photos = [];

        snapshot.forEach(doc => {
          const data = doc.data();
          if (data.photo) {
            photos.push(data.photo);
          }
        });

        setProductPhotos(photos);
      } catch (error) {
        console.error("Error fetching slideshow photos:", error);
      }
    };

    fetchPhotos();
  }, [puid]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % productPhotos.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? productPhotos.length - 1 : prevIndex - 1
    );
  };

  const startSlideshow = useCallback(() => {
    if (intervalRef.current || productPhotos.length <= 1) return;
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % productPhotos.length);
    }, 3000);
  }, [productPhotos]);

  const stopSlideshow = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    startSlideshow();
    return () => stopSlideshow();
  }, [startSlideshow, stopSlideshow]);

  if (!productPhotos || productPhotos.length === 0) {
    return <div className="text-gray-500">No photos available</div>;
  }

  return (
    <div className="container_roots">
      <Helmet>
        <title>Order Custom QR Codes | CallQR</title>
        <meta name="description" content="Easily order customized QR codes for your business, events, parking, menus, and more. Get high-quality, ready-to-use QR designs from CallQR." />
        <meta name="keywords" content="Order QR Code, Custom QR Code, Buy QR Code, Business QR Order, QR Code Printing, Event QR Code, Parking QR Tag, Menu QR Code, CallQR Order" />
        <meta name="author" content="callqr.com" />
        <meta property="og:title" content="Order Custom QR Codes | CallQR" />
        <meta property="og:description" content="Easily order custom QR codes for your needs. Business, event, or parking QR solutions delivered quickly." />
        <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/order" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CallQR" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Order Custom QR Codes | CallQR" />
        <meta name="twitter:description" content="Order high-quality custom QR codes for your business or personal use with CallQR." />
        <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
        <meta name="twitter:site" content="@CALLQR_" />
        <meta name="twitter:url" content="https://x.com/CALLQR_" />
        <meta name="google-play-app" content="app-id=callqr.com" />
        <meta name="theme-color" content="#000000" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="CallQR" />
      </Helmet>

      <div className="container_size">
        <div className="amazon-order-container">
          <div className="amazon-order-left">
            <div
              className="relative-order-left"
              onMouseEnter={stopSlideshow}
              onMouseLeave={startSlideshow}
            >
              <img
                src={productPhotos[currentIndex]}
                alt={`Product ${currentIndex + 1}`}
                className="photo-img"
              />
              {productPhotos.length > 1 && (
                <div className="nav_div">
                  <button
                    onClick={handlePrev}
                    aria-label="Previous image"
                    className="Previous-btn absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white px-3 py-1 rounded-l focus:outline-none"
                  >
                    ‹
                  </button>
                  <button
                    onClick={handleNext}
                    aria-label="Next image"
                    className="Next-btn absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white px-3 py-1 rounded-r focus:outline-none"
                  >
                    ›
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="amazon-order-right">
            <div className="product-title">{TitleValue}</div>
            <div className="product-price">₹{PriceValue}/- INR</div>
            <div className="order-options">
              <label>
                Quantity:
                <select className="qty-select">
                  {[1, 2, 3, 4, 5].map((qty) => (
                    <option key={qty} value={qty}>{qty}</option>
                  ))}
                </select>
              </label>
              <div className="product-validity">{ValidityValue}</div>
              <button className="buy-button">Buy Now</button>
            </div>
          </div>
        </div>
      </div>

      <div className="product-description">
        <div className='product-description-hd'>
          <b>Product Description</b>
          <div>ID: {puid}</div>
        </div>
        <br />
        <div dangerouslySetInnerHTML={{ __html: DescriptionValue }}></div>
      </div>
    </div>
  );
}
