import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet"; 
import  ButtonWithImage from '../components/ButtonWithImage';
export default function Userguide() {
  return (
    <div className="container_roots">
  
  <Helmet>
  <title>How to Use CallQR | Step-by-Step QR Code User Guide</title>
  <meta name="description" content="Learn how to use CallQR to create and scan QR codes for business, events, payments, and more. Follow our easy step-by-step user guide." />
  <meta name="keywords" content="How to use QR code generator, CallQR user guide, QR instructions, QR code for business, scan QR code help, CallQR manual, step-by-step QR guide" />
  <meta name="author" content="callqr.com" />
  <meta name="robots" content="index, follow" />

  {/* Open Graph */}
  <meta property="og:title" content="How to Use CallQR | Step-by-Step QR Code User Guide" />
  <meta property="og:description" content="Follow our simple user guide to create and scan QR codes with CallQR. Perfect for businesses, events, and more." />
  <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
  <meta property="og:url" content="https://callqr.com/user_guide" />
  <meta property="og:type" content="article" />
  <meta property="og:site_name" content="CallQR" />

  {/* Twitter/X Card */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="How to Use CallQR | Step-by-Step QR Code User Guide" />
  <meta name="twitter:description" content="Learn how to use CallQR with our simple and fast QR code user guide." />
  <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
  <meta name="twitter:site" content="@CALLQR_" />
  <meta name="twitter:url" content="https://x.com/CALLQR_" />

  {/* App Deep Link */}
  <meta name="google-play-app" content="app-id=callqr.com" />

  {/* Mobile Optimization */}
  <meta name="theme-color" content="#000000" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="apple-mobile-web-app-title" content="CallQR" />
</Helmet>

      <div className="container_size">
      <h1 className="contact-title">user_guide</h1>
      </div>
</div>
    );
}
