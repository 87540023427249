import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet"; 
import  ButtonWithImage from '../components/ButtonWithImage';
import './tearms.css';
export default function Privacypolicy() {
  return (
    <div className="container_roots">
  <Helmet>
  <title>Privacy Policy | CallQR</title>
  <meta name="description" content="Read the CallQR Privacy Policy to learn how we collect, use, and protect your personal information while using our QR code generation and scanning services." />
  <meta name="keywords" content="CallQR Privacy Policy, QR App Privacy, Data Protection, QR Code App Policy, User Privacy, Personal Data, App Permissions, CallQR Terms" />
  <meta name="robots" content="index, follow" />
  <meta name="author" content="callqr.com" />

  {/* Open Graph */}
  <meta property="og:title" content="Privacy Policy | CallQR" />
  <meta property="og:description" content="Learn how CallQR protects your personal information and ensures secure QR code generation and scanning." />
  <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
  <meta property="og:url" content="https://callqr.com/privacy-policy" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CallQR" />

  {/* Twitter/X Card */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Privacy Policy | CallQR" />
  <meta name="twitter:description" content="Your privacy matters. See how CallQR handles and secures your data during QR code use." />
  <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
  <meta name="twitter:site" content="@CALLQR_" />
  <meta name="twitter:url" content="https://x.com/CALLQR_" />

  {/* Google Play App Linking */}
  <meta name="google-play-app" content="app-id=callqr.com" />

  {/* Mobile Optimization */}
  <meta name="theme-color" content="#000000" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="apple-mobile-web-app-title" content="CallQR" />
</Helmet>

      <div className="container_size">
      <h1 className="contact-title">privacy policy</h1>
      <p><strong>Last Updated:</strong> 01 April 2025</p>
    
    <p>Welcome to Callqr.com ("we," "our," or "us"). Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your data when using our mobile application ("App").</p>

<h2>1. Information We Collect</h2>
<h3>1.1 Personal Information</h3>
<ul>
    <li><strong>Location Data:</strong> If required for app features, we may collect your precise or approximate location.</li>
    <li><strong>Camera Access:</strong> Used for QR code scanning and other features.</li>
    <li><strong>Storage Access:</strong> Required to save or retrieve necessary files.</li>
</ul>

<h3>1.2 Non-Personal Information</h3>
<p>We may collect data such as device type, operating system version, and app usage statistics for performance improvements.</p>

<h2>2. How We Use Your Information</h2>
<ul>
    <li>To provide and improve app functionality.</li>
    <li>To store and retrieve files from your device.</li>
    <li>To send notifications (only with user consent).</li>
</ul>

<h2>3. Permissions We Use</h2>
<table>
    <tr>
        <th>Permission</th>
        <th>Purpose</th>
    </tr>
    <tr>
        <td>ACCESS_FINE_LOCATION / ACCESS_COARSE_LOCATION</td>
        <td>Used for location-based features.</td>
    </tr>
    <tr>
        <td>CAMERA</td>
        <td>Required for scanning QR codes.</td>
    </tr>
    <tr>
        <td>READ_EXTERNAL_STORAGE / WRITE_EXTERNAL_STORAGE</td>
        <td>Used for saving and retrieving files.</td>
    </tr>
    <tr>
        <td>POST_NOTIFICATIONS</td>
        <td>Allows the app to send notifications (user-controlled).</td>
    </tr>
</table>

<h2>4. Data Sharing & Third Parties</h2>
<p>We do not sell or rent your personal data. However, we may use third-party services such as Google Analytics for performance tracking.</p>

<h2>5. Data Security</h2>
<p>We follow industry standards to protect your data from unauthorized access, but no method is 100% secure.</p>

<h2>6. Your Rights & Choices</h2>
<ul>
    <li>You can manage app permissions from your device settings.</li>
    <li>You can disable notifications anytime in app settings.</li>
</ul>

<h2>7. Changes to This Privacy Policy</h2>
<p>We may update this Privacy Policy. Changes will be posted on this page.</p>

<h2>8. Contact Us</h2>
<p>If you have any questions, contact us at:</p>
<p><strong>Email:</strong> support@callqr.com</p>
<p><strong>Website:</strong> <a href="https://callqr.com">https://callqr.com</a></p>

      </div>
</div>
    );
}
