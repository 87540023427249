import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import Cookies from 'js-cookie';
import { db } from "../firebase";  
import { getFirestore, doc, setDoc, getDoc, updateDoc, collection } from "firebase/firestore";
import Phonelogin from '../pages/phonelogin';
import { IoLinkOutline } from "react-icons/io5";
import { IoQrCodeOutline } from "react-icons/io5";
import "./design_menu.css";
import { PiFolderSimpleLock } from "react-icons/pi";
export default function Designmenu() {
    const navigate = useNavigate();
    const location = useLocation(); // Get current route
 const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const fetchUserData = async () => {
            const existingUser = Cookies.get("guid");
            if (existingUser) {            
                setIsVisible(true);               
              
            } else {
        
                setIsVisible(false);
            }
        };    
        fetchUserData();
    }, []);
    return (
        <div className="design-menu">
            <div className="button-body">
                <div 
                    onClick={() => navigate('/')} 
                    className={`design-menu-button ${location.pathname === '/' ? 'active' : ''}`} 
                    title="QR Code"
                >
                    <IoQrCodeOutline className="menubutton-icon" />
                    <span className="button-text">QR Code</span>                         
                </div>  
                <div 
                    onClick={() => navigate('/short-url')} 
                    className={`design-menu-button ${location.pathname === '/short-url' ? 'active' : ''}`} 
                    title="Short URL"
                >
                    <IoLinkOutline className="menubutton-icon" />
                    <span className="button-text">Short URL</span>                         
                </div>  
            </div> 
            <div className="orders-button">
  <div 
    onClick={() => {
      if (isVisible) {
        navigate('/projects');
      } else {
         
      }
    }} 
    className={`design-menu-button ${location.pathname === '/projects' ? 'active' : ''}`} 
    title="Projects"
  >
    <PiFolderSimpleLock className="menubutton-icon" />
    <span className="button-text">Projects</span>                         
  </div>
</div> 
        </div>
    );
}
