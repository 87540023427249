import React from "react";
import { Helmet } from "react-helmet";
import './for-business.css';

export default function Forbusiness() {
  return (
    <div className="container_roots">
      <Helmet>
        <title>QR Code Solutions for Business | CallQR</title>
        <meta name="description" content="Boost your business with CallQR’s powerful QR code solutions. Create branded QR codes for marketing, payments, menus, ID cards, customer engagement, and more." />
        <meta name="keywords" content="QR Code for Business, Business QR Generator, Branded QR Codes, QR for Marketing, QR for Payments, QR for Menus, QR for ID Cards, CallQR Business Solutions" />
        <meta name="author" content="callqr.com" />
        <meta name="robots" content="index, follow" />

        {/* Open Graph */}
        <meta property="og:title" content="QR Code Solutions for Business | CallQR" />
        <meta property="og:description" content="Boost your business with CallQR’s QR solutions for marketing, menus, payments, and customer engagement." />
        <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/for-business" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CallQR" />

        {/* Twitter/X Meta */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="QR Code Solutions for Business | CallQR" />
        <meta name="twitter:description" content="Create and manage business-ready QR codes with CallQR. Perfect for marketing, payments, menus, and more." />
        <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
        <meta name="twitter:site" content="@CALLQR_" />
        <meta name="twitter:url" content="https://x.com/CALLQR_" />

        <meta name="google-play-app" content="app-id=callqr.com" />
        <meta name="theme-color" content="#000000" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="CallQR" />
      </Helmet>

      <div className="container_size">
        <div className="hero">
      
            <h1 className="title">Business Services - CallQR</h1>
            <p className="description">
              At CallQR, we specialize in delivering comprehensive solutions for seamless business operations under state-of-the-art systems.
              <b> Our services include managing in/out entries</b>, cashbooks, staff accounts, and tracking cash received by staff to ensure financial transparency and efficiency.<br /><br />
              As a trusted service operator, we provide tailored solutions designed to suit both personal and professional needs, empowering you to focus on growth while we handle the intricacies of your business processes. Our flexible plans adapt to your unique requirements, helping you streamline operations with ease.
            </p>
    
        </div>

      
          <div className="purchase_for">
            <div className="purchase_title">
              <strong>Membership Management</strong><br />
              Simplify your membership-based operations with our innovative tools. Perfect for gyms, clubs, and similar setups, our system allows seamless management of member check-ins and check-outs.
            </div>
            <div className="tag_img"></div>
          </div>

          <section className="pricing">
            <div className="pricing-table">
              <div className="pricing-card">
                <h3>Purchase Business Tools</h3>
                <p className="price">₹2999/-</p>
                <ul>
                  <li>✅ Sale, Call QR Tag, and Earnings</li>
                  <li>✅ Manage Staff and Cashbook</li>
                  <li>✅ Membership Management</li>
                  <li>✅ Annual Subscription</li>
                  <li>✅ Validity 365 Days</li>
                  <li>✅ New Features Update</li>
                  <li>✅ All Features Included</li>
                </ul>
              </div>

              <div className="pricing-card">
                <h3>How to Purchase</h3>
                <ul>
                  <li>1. Download the CallQR App</li>
                  <li>2. Find and select the CallQR service</li>
                  <li>3. Choose Purchase to complete the transaction</li>
                </ul>
                <a href="/download" className="btn_btn">Start Now</a>
              </div>
            </div>
            <p className="info-text">
              Make a smart investment in your business's future with our tailored business plans. Designed to suit your unique requirements, our plans help streamline operations, manage finances efficiently, and support growth.
            </p>
          </section>

          <section className="description">
            <h2>Customizable Services</h2>
            <div className="card-container">
              {[
                "Token Management",
                "Ticketing Services",
                "Laundry Charges",
                "Delivery Services",
                "Utilities Management",
                "Cleaning Services",
                "Grocery Management",
                "Repair Services",
                "Pet Care Services",
                "Storage Solutions",
                "Catering Services",
                "Childcare Services",
                "Event Management",
                "Transport Services",
                "Fitness Services",
                "Concierge Services",
                "Recycling Programs",
                "Tech Help Services",
                "Gardening Services"
              ].map((service, index) => (
                <div className="card" key={index}>
                  <h3>{service}</h3>
                  <p>Get efficient & customizable service options tailored for your needs.</p>
                </div>
              ))}
            </div>
          </section>

          <section className="description">
            <h2>Why Choose CallQR?</h2>
            <a>
              Buy CallQR today and download the app to enjoy secure and seamless communication.
              Place a CallQR Tag on your gadgets, and anyone who scans it can easily contact you by revealing your phone number.
              With CallQR, you'll not only see the caller's real name, location, and identity details instantly,
              but you'll also have access to all the information shared through the QR code. Stay in control and informed with CallQR!
            </a>
          </section>
       
      </div>
    </div>
  );
}
