import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './faq.css';
function FaqPage() {
    const faqs = [
        {
          question: 'What is CallQR?',
          answer: 'CallQR is a user-friendly QR code generator app that enables you to create, customize, and scan QR codes instantly. It is designed for both personal and professional use.'
        },
        {
          question: 'Is the CallQR app free to use?',
          answer: 'Yes, CallQR is completely free to download and use. It provides all core features without any hidden charges.'
        },
        {
          question: 'Can I customize my QR codes?',
          answer: 'Absolutely! With CallQR, you can create custom QR codes with different colors, logos, and sizes tailored to your branding or needs.'
        },
        {
          question: 'Is an internet connection required to scan QR codes?',
          answer: 'CallQR allows scanning QR codes offline. However, accessing linked content like websites will require an internet connection.'
        },
        {
          question: 'Where can I download the CallQR app?',
          answer: 'You can download CallQR from the Play Store here: https://play.google.com/store/apps/details?id=callqr.com'
        },
        {
          question: 'What types of QR codes can I create?',
          answer: 'You can generate QR codes for URLs, contacts, email addresses, phone numbers, WiFi credentials, business cards, parking info, events, and more.'
        },
        {
          question: 'Is CallQR suitable for businesses?',
          answer: 'Yes, CallQR is perfect for businesses. It lets you create professional QR codes for menus, ID cards, product labels, payment links, and digital marketing.'
        },
        {
          question: 'Does CallQR save my QR code history?',
          answer: 'Yes, CallQR includes a history feature that stores your generated and scanned QR codes for future reference and re-use.'
        },
        {
          question: 'Is CallQR secure?',
          answer: 'Yes, CallQR ensures your QR codes are generated securely on your device. It does not store your personal information or scan data on any server.'
        },
        {
          question: 'Can I share my QR code from the app?',
          answer: 'Yes! You can easily share your created QR codes through WhatsApp, Email, or other social platforms directly from the app.'
        }
      ];
      

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <Helmet>
        <title>FAQs | CallQR - QR Code Generator App</title>
        <meta name="description" content="Find answers to common questions about CallQR. Learn how to generate and scan QR codes with our free QR code app." />
        <meta name="keywords" content="QR Code FAQ, CallQR Help, QR Code App Support, QR Questions, How to Scan QR, QR App Help" />
        <meta property="og:title" content="FAQs | CallQR - QR Code Generator App" />
        <meta property="og:description" content="Learn more about the CallQR app. Frequently asked questions about using and customizing QR codes." />
        <meta property="og:url" content="https://callqr.com/faq" />
      </Helmet>

      <div className="faq-container">
  <h1 className="faq-title">Frequently Asked Questions</h1>
  {faqs.map((faq, index) => (
    <div
      key={index}
      className={`faq-item ${openIndex === index ? 'open' : ''}`}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">
        {faq.question}
        <span className="faq-icon">{openIndex === index ? '▲' : '▼'}</span>
      </div>
      <div className="faq-answer">{faq.answer}</div>
    </div>
  ))}
</div>

    </>
  );
}

export default FaqPage;
