import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'; 
import Buttontheme from '../components/Buttontheme';
const Homedataproducts = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerPosition = 300;  // Trigger animation at 300px
      setVisible(scrollPosition > triggerPosition);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const cardData = [
    {
      puid:'FFAeFhPNqfMy3dmLLHpD',
      img: 'https://firebasestorage.googleapis.com/v0/b/callqr-ab941.appspot.com/o/ads%2Fcallqr_order%2Fbusiness_tools_ads.jpg?alt=media&token=751d0f46-c95e-4a6b-b5a2-c183e609a7ca',
      title: 'Parking & Business Tools',
      description: `Efficiently manage guest entries, attendance, tokens, tickets, cashbook, and staff.
        <br><b>Perfect for gyms, clubs, and similar setups, allowing seamless check-ins and check-outs.</b>`
    },
    { puid:'WmwHfeLT4yCmomhqj6',
      img: 'https://firebasestorage.googleapis.com/v0/b/callqr-ab941.appspot.com/o/ads%2Fcallqr_order%2Fqr_code_download_app.png?alt=media&token=42a87f99-ab45-4b8c-8abc-f9e8b0520726',
      title: 'CallQR',
      description: `<b>Vehicle parking safety tag:</b> Scan QR to contact the vehicle owner.
        <br>Use Case:
        <br><b>Car:</b> Paste a CallQR tag on the windshield.
        <br><b>Bike:</b> Place a CallQR tag on the front plain surface.`
    },
    {
      puid:'PeBJO6PDEsxvKpcwtHOJ',
      img: 'https://firebasestorage.googleapis.com/v0/b/callqr-ab941.appspot.com/o/ads%2Fcallqr_order%2Fid_card_ads.jpg?alt=media&token=3f223e51-bfa0-4f0b-8d51-8b4dc7064487',
      title: 'SmartID Card',
      description: `<b>ID Card & Attendance Management System with CallQR</b>
        <br>Manage staff entries and attendance through a smart ID card system with advanced QR technology.`
    },
    {
      puid:'7cv2OOOJscJT8v0rUp9Z',
      img: 'https://firebasestorage.googleapis.com/v0/b/callqr-ab941.appspot.com/o/ads%2Fcallqr_order%2Fqr_code_ads.jpg?alt=media&token=322fbbf1-6976-4bdf-87b4-eac39a5a90ae',
      title: 'QR Code',
      description: `<b>QR Card:</b> Smart QR-Based Assistance for Public & Government Services.
        <br>Enables users to request assistance seamlessly with real-time ticket tracking.`
    },
    {
      puid:'KoNebFOM8IoOwXWncQpa',
      img: 'https://firebasestorage.googleapis.com/v0/b/callqr-ab941.appspot.com/o/ads%2Fcallqr_order%2Fhelp_card_ads.jpg?alt=media&token=c01ccae3-5f4a-4626-b7e0-00334ee82413',
      title: 'Help Card',
      description: `<b>Help Card: Empowering Citizens, Connecting Authorities</b>
        <br>Request assistance by scanning the QR code. Manage service tokens and track ticket status.`
    },
    {
      puid:'qe4SLT2DH2nZdqSLR1jV',
      img: 'https://firebasestorage.googleapis.com/v0/b/callqr-ab941.appspot.com/o/ads%2Fcallqr_order%2Fevent_qr_ads.jpg?alt=media&token=22dda907-ae0a-47cd-986e-d083f8b2dce4',
      title: 'Event Card',
      description: `<b>Event Card:Manage entries and attendance with CallQR.</b>
        <br>Efficient guest management with a real-time control panel.`
    }
    ,
    {
      puid:'SE9lODqrICA5O2e5Z0YE',
      img: 'https://firebasestorage.googleapis.com/v0/b/callqr-ab941.appspot.com/o/ads%2Fcallqr_order%2Fevent_qr_ads.jpg?alt=media&token=22dda907-ae0a-47cd-986e-d083f8b2dce4',
      title: 'Short url',
      description: `<b>ShortURL - Instantly Shorten Long Links for Easy Sharing</b>
        <br>ShortURL is a simple and fast tool to convert long URLs into short, easy-to-share links. Perfect for social media, messaging, and more! `
    }
  ];

  return (
    <div className="show-case-prodcut-body">
      {/* Title Section */}
      <motion.div
        className="title-div"
        initial={{ opacity: 0, y: 50 }}
        animate={visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
      >
        <div className="container_size">
          <div className="title-text">Products and Services</div>
          <div className="desc-text">CallQR – Smart QR Solutions for Seamless Communication</div>
        </div>
      </motion.div>

      {/* Card Section */}
      <div className="container_size">
      <div className="features-page">
        <div className='features-grid'>
        {cardData.map((card, index) => (
           
          <motion.div 
          onClick={() => window.location.href = './order?puid='+card.puid}
            key={index}
            className="feature-card"
            initial={{ opacity: 0, y: 50 }}
            animate={visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.8, ease: 'easeInOut', delay: index * 0.2 }}
          >
             
             <img
              className="card_pro_img" src={card.img}
            ></img>
            <div className="title_text">{card.title}</div>
            <div className="desc_text" dangerouslySetInnerHTML={{ __html: card.description }} />
          <br/>
          <Buttontheme
          title={card.title}
          buttonText={'Read more'}
          />
          </motion.div>

        ))}
      </div>
      </div>
    </div>
    </div>
  );
};

export default Homedataproducts;
