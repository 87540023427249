import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet"; 
import  ButtonWithImage from '../components/ButtonWithImage';
export default function Search() {
    const query = new URLSearchParams(window.location.search).get('q');
  return (
    <div className="container_roots">
  
  <Helmet>
  <title>Search QR Codes | CallQR</title>
  <meta name="description" content="Search QR codes, business QR, parking QR, and more on CallQR. Discover instant QR code results for your query. Try now!" />
  <meta name="keywords" content="Search QR, QR Code Results, QR Generator Search, QR App, CallQR Search, QR Code Finder, Business QR Codes, Free QR Code Lookup" />
  <meta name="robots" content="noindex, follow" /> 
  <meta name="author" content="callqr.com" />

  {/* Open Graph */}
  <meta property="og:title" content="Search QR Codes | CallQR" />
  <meta property="og:description" content="Search QR code data with CallQR. Get instant results for any QR-related search." />
  <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
  <meta property="og:url" content="https://callqr.com/search" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CallQR" />

  {/* Twitter/X Card */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Search QR Codes | CallQR" />
  <meta name="twitter:description" content="Use CallQR to instantly search and discover QR codes for any purpose. Try now!" />
  <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
  <meta name="twitter:site" content="@CALLQR_" />
  <meta name="twitter:url" content="https://x.com/CALLQR_" />
  <meta name="description" content={query 
    ? `Search results for "${query}" on CallQR. Find QR codes for business, parking, and more.` 
    : "Search QR codes, business QR, parking QR, and more on CallQR. Discover instant QR code results for your query. Try now!"} />
  {/* keep rest as-is */}
  {/* Google Play App Linking */}
  <meta name="google-play-app" content="app-id=callqr.com" />

  {/* Mobile Optimization */}
  <meta name="theme-color" content="#000000" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="apple-mobile-web-app-title" content="CallQR" />
</Helmet>
      <div className="container_size">
      <h1 className="contact-title">Search</h1>
      </div>
</div>
    );
}
