import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
import "./Contactus.css";  // Import the CSS file
import  ButtonWithImage from '../components/ButtonWithImage';
export default function Contactus() {
  return (
    <div className="container_roots">
  
  <Helmet>
  <title>Contact Us | CallQR - Free QR Code Generator</title>
  <meta name="description" content="Have questions or need support? Contact CallQR for assistance with QR code generation, scanning, app usage, or general inquiries." />
  <meta name="keywords" content="Contact CallQR, CallQR Support, QR Code Help, Contact QR App Team, QR Generator Support, CallQR Assistance, QR Code Customer Service" />
  <meta name="robots" content="index, follow" />
  <meta name="author" content="callqr.com" />

  {/* Open Graph */}
  <meta property="og:title" content="Contact Us | CallQR" />
  <meta property="og:description" content="Reach out to CallQR for support with QR code generation, scanning, or general inquiries." />
  <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
  <meta property="og:url" content="https://callqr.com/contact-us" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CallQR" />

  {/* Twitter/X Card */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Contact Us | CallQR" />
  <meta name="twitter:description" content="Need help? Contact CallQR for support and inquiries about QR code creation and the CallQR app." />
  <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
  <meta name="twitter:site" content="@CALLQR_" />
  <meta name="twitter:url" content="https://x.com/CALLQR_" />

  {/* Google Play App Link */}
  <meta name="google-play-app" content="app-id=callqr.com" />

  {/* Mobile Optimization */}
  <meta name="theme-color" content="#000000" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="apple-mobile-web-app-title" content="CallQR" />
</Helmet>


      <h1 className="contact-title">Contact Us</h1>

 

  <div  className="container_size">
  {/* Left Section - Contact Info */}
  <div className="contact-info">
    <div className="info-box">
      <FaPhone className="icon" />
      <b>Phone</b>
      <p>+91 95306 21728</p>
    </div>

    <div className="info-box">
      <FaEnvelope className="icon" />
      <b>Email</b>
      <p>support@callqr.com</p>
    </div>

    <div className="info-box">
      <FaMapMarkerAlt className="icon" />
      <b>Address</b>
      <p>192 Defence Colony, Malerkotla, Punjab, India</p>
    </div>
  </div>

  {/* Right Section - Contact Form */}
  <div className="contact-form">
    <form>
      <div className="form-group">
        <label>Name</label>
        <input type="text" placeholder="Your Name" required />
      </div>

      <div className="form-group">
        <label>Email</label>
        <input type="email" placeholder="Your Email" required />
      </div>

      <div className="form-group">
        <label>Message</label>
        <textarea placeholder="Your Message" rows="5" required></textarea>
      </div>

      <ButtonWithImage 
title="Submit your Query"
  buttonText="Submit" 
  buttonLink="https://callqr.com"
/>
    </form>
  </div>
 </div>

{/* Google Map */}
<div  className="container_size">
<div className="map-container">
  <iframe
    title="Google Map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3517.3659263920396!2d75.89129617556526!3d30.526644698688536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a94c0f5e8b7b1%3A0x4eb7e67c817b0d1!2sMalerkotla%2C%20Punjab!5e0!3m2!1sen!2sin!4v1711539331315!5m2!1sen!2sin"
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
 
</div>
    </div>
    </div>
  );
}
