import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const Ourfounders = () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerPosition = 300; // Trigger animation at 300px
      setVisible(scrollPosition > triggerPosition);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const cardData = [
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/callqr-ab941.appspot.com/o/files%2Frupinder_singh_kang_founder_ceo_callqr.jpg?alt=media&token=63eedaaa-128a-461f-9046-5dbf692cf7c1',
      title: 'Rupinder Singh Kang',
      description: `Founder & CEO - Software Developer</b>`
    }
  ]

  return (
    <div style={{ ...styles.showCaseBody }}>
      <motion.div
        className="animated-box"
        initial={{ opacity: 0, y: 50 }}
        animate={visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
      >
        <div className='container_size'>
          <div class="title-div">
            <div class="title-text">
              Our Founders
            </div>
            <div className="desc-text">Introducing the Brains Behind Callqr.
            </div>
          </div>
   {/* Card Section */}
      <div className="container_size card-container" >
        {cardData.map((card, index) => (
          <motion.div
            key={index}
            className="Ourfounders_card_view"
            initial={{ opacity: 0, y: 50 }}
            animate={visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.8, ease: 'easeInOut', delay: index * 0.2 }}
          >
            <img
              className="Ourfounders_img" src={card.img}
            ></img>
            <b className="info_text" dangerouslySetInnerHTML={{ __html: card.title}}></b>
            <div className="info_text" dangerouslySetInnerHTML={{ __html: card.description }} />
          </motion.div>
        ))}
      </div>

        </div>
      </motion.div>
    </div>
  );
};
const styles = {
  showCaseBody: {
    padding: '50px 20px',
    margin: '0px 0px'
  }
};
export default Ourfounders;
