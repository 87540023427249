import React from "react";
import { Helmet } from "react-helmet";
import "./troubleshoot.css";

export default function Troubleshoot() {
  return (
    <div className="help-center-root">
      <Helmet>
      <title>Fix an Issue | CallQR Troubleshooting Help & Solutions</title>
  <meta name="description" content="Having trouble with the CallQR app? Explore troubleshooting tips, solutions to common issues, and FAQs. Get back to creating and scanning QR codes with ease!" />
  <meta name="keywords" content="CallQR Help, QR Code App Issues, Troubleshooting CallQR, CallQR Not Working, QR App Fix, CallQR FAQ, Fix QR Code App, Scan Issues, QR Code Generator Error, CallQR Support" />
  <meta name="author" content="callqr.com" />
 
  <meta property="og:title" content="Fix an Issue | CallQR Troubleshooting Help & Solutions" />
  <meta property="og:description" content="Resolve issues with the CallQR app. Learn how to fix scanning errors, login problems, and more." />
  <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
  <meta property="og:url" content="https://callqr.com/troubleshoot" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CallQR" />
 
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Fix an Issue | CallQR Help" />
  <meta name="twitter:description" content="Need help with the CallQR app? Get troubleshooting tips and solutions to fix any issue quickly." />
  <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
  <meta name="twitter:site" content="@CALLQR_" />
  <meta name="twitter:url" content="https://x.com/CALLQR_" />
 
  <link rel="canonical" href="https://callqr.com/fix-an-issue" />
  <meta name="robots" content="index, follow" />  </Helmet>

      <div className="help-center-container">
        <h1 className="help-title">🛠 Troubleshooting Guide</h1>
        <p className="help-subtitle">Find solutions to common issues when using CallQR QR codes.</p>

        <div className="issue-list">
          <div className="issue-card">
            <h3>📷 QR Code Not Scanning</h3>
            <ul>
              <li>Make sure there's enough lighting.</li>
              <li>Clean your camera lens.</li>
              <li>Ensure the QR code is not distorted or blurred.</li>
              <li>Try a different scanner app.</li>
            </ul>
          </div>

          <div className="issue-card">
            <h3>🖨 Print Quality Issues</h3>
            <ul>
              <li>Use high-resolution image for printing.</li>
              <li>Don’t resize QR images manually — use our export options.</li>
              <li>Avoid printing on glossy or reflective surfaces.</li>
            </ul>
          </div>

          <div className="issue-card">
            <h3>🔗 QR Code Opens Wrong Page</h3>
            <ul>
              <li>Double-check your destination URL in the CallQR dashboard.</li>
              <li>Make sure the QR code was updated after editing the link.</li>
              <li>Clear browser cache if the old page still appears.</li>
            </ul>
          </div>

          <div className="issue-card">
            <h3>📡 QR Code Doesn't Work Offline</h3>
            <ul>
              <li>Ensure the linked content is available offline or saved locally.</li>
              <li>Consider generating a static QR code with embedded data.</li>
            </ul>
          </div>

          <div className="issue-card">
            <h3>Still Need Help?</h3>
            <p>Contact our support team for personalized assistance.</p>
            <a href="/help-center" className="help-link">Go Back to Help Center</a>
          </div>
        </div>
      </div>
    </div>
  );
}
