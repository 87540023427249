 
import { initializeApp } from "firebase/app";   // ✅ Only one import
import Cookies from 'js-cookie';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // ✅ IMPORTANT for image upload

const firebaseConfig = {
  apiKey: "AIzaSyBBrDds_05fvxejfzmm5gm4ywDQIlEO6E4",
  authDomain: "callqr-ab941.firebaseapp.com",
  databaseURL: "https://callqr-ab941-default-rtdb.firebaseio.com",
  projectId: "callqr-ab941",
  storageBucket: "callqr-ab941.appspot.com",
  messagingSenderId: "331322470394",
  appId: "1:331322470394:web:4ebc4a4f1d769c0ef2fe43",
  measurementId: "G-H8XG5726FS"
}; 
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // ✅ Add this

// Export everything needed
export {
  auth,
  db,
  storage, // ✅ Required for EasyImageUploader
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut
};