import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'; 

const Homedatainformation = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerPosition = 300;  // Trigger animation at 300px
      setVisible(scrollPosition > triggerPosition);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const cardData = [
    {
   
      img: require("../assets/user_centric_design.png"),
      title: 'What Makes CallQR Unique?',
      description: `
        <b>One Scan, Complete Information</b> - A single scan reveals all essential details—name, contact, location, and identity—without manual sharing.<br />
        <b>Privacy First</b> - Keep your phone number hidden while staying accessible.<br />
        <b>AI-Powered Security</b> - Live tracking, verified users & spam protection keep interactions safe.<br />
        <b>Instant Caller ID</b> - Know who is calling, their location, and why—before answering.<br />
        <b>Earn While You Connect</b> - Get cash-back rewards every time someone scans your CallQR.
      `
    },
    { img: require("../assets/advanced_security.png"),
      title: 'More Than Just Calls - A Digital Identity for Everything',
      description: `
        <b>Smart Digital Profiles</b> - Use CallQR for personal and business branding—share details instantly.<br />
        <b>Seamless Membership & Attendance </b> - Ideal for schools, gyms, and corporate offices.<br />
        <b>Revolutionary Parking System </b> - Attach a CallQR tag to your vehicle, allowing others to contact you without revealing your number.<br />
        <b>Customizable Digital Cards </b> - Design visiting cards, ID badges, and business cards with built-in CallQR integration.
      `
    },
    { 
      img: require("../assets/innovative_technology.png"),
      title: 'The Future of Secure & Effortless Communication',
      description: `
        <b>CallQR isn’t just an app — it’s a revolution.</b> One scan is all it takes to <b>connect, verify, and protect your information in a digital-first world.</b>
        <ul>
          <li><strong>Enhanced Security:</strong> Verify identities and protect your data with advanced encryption.</li>
          <li><strong>User-Friendly:</strong> Simple, fast, and designed for everyone.</li>
        </ul>
        <b>Get Started for FREE – <br />Download CallQR Today!</b>
      `
    }
  ];

  return (
    <div className="container_size">
      <div className="features-page">
        <div className='features-grid'>

        {cardData.map((card, index) => (
          <motion.div
            key={index}
            className="feature-card"
            initial={{ opacity: 0, y: 50 }}
            animate={visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.8, ease: 'easeInOut', delay: index * 0.2 }}
          >
            <div
              className="card_img"
              style={{ backgroundImage: `url(${card.img})` }}
            ></div>
            
            <div className="title_text">{card.title}</div>
            <div
              className="desc_text"
              dangerouslySetInnerHTML={{ __html: card.description }}
            />
          </motion.div>
        ))}
  </div>
      </div>
    </div>
  );
};

export default Homedatainformation;
