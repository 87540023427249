import React from 'react';
import './DownloadPage.css';
import { Helmet } from "react-helmet";
import { IoMdDownload } from "react-icons/io";  // Import download icon
import managementservices from '../assets/management_services.jpg'

const DownloadPage = () => {
    <Helmet>
  <title>Download CallQR App | Free QR Code Generator & Scanner</title>
  <meta name="description" content="Download the CallQR app from the Play Store. Generate and scan QR codes instantly for websites, events, parking, ID cards, and more. Free, fast, and easy to use." />
  <meta name="keywords" content="Download QR App, CallQR APK, QR Code Generator App, Free QR Code Scanner, Android QR App, QR for Events, Parking QR Code, CallQR Download, CallQR Play Store" />
  <meta name="author" content="callqr.com" />
  <meta name="robots" content="index, follow" />

  {/* Open Graph */}
  <meta property="og:title" content="Download CallQR App | Free QR Code Generator & Scanner" />
  <meta property="og:description" content="Install the CallQR app and start creating and scanning QR codes instantly. Available on Google Play Store for free." />
  <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
  <meta property="og:url" content="https://callqr.com/download" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CallQR" />

  {/* Twitter/X Card */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Download CallQR App | Free QR Code Generator & Scanner" />
  <meta name="twitter:description" content="Create and scan QR codes instantly with the CallQR app. Download it free from the Google Play Store." />
  <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
  <meta name="twitter:site" content="@CALLQR_" />
  <meta name="twitter:url" content="https://x.com/CALLQR_" />

  {/* App Deep Linking */}
  <meta name="google-play-app" content="app-id=callqr.com" />

  {/* Mobile Optimization */}
  <meta name="theme-color" content="#000000" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="apple-mobile-web-app-title" content="CallQR" />
</Helmet>

    const handleDownload = () => {
        // Replace with your file URL or dynamic download logic
        const fileUrl = "https://play.google.com/store/apps/details?id=callqr.com";
        window.open(fileUrl, '_blank');
    };

    return (
        <div className="container_roots">
            <div class="container_size">

                <div class="title_body">

                    <div class="download_body">

                        <h1 class="title">Download CallQR App</h1>

                        <p class="description">Get started with <b>CallQR for FREE</b>—it's safe and easy to contact with just a scan!</p>

                        <p>By installing Callqr App, you agree to our <a href="termsofservice">Terms</a>  &<a href="privacypolicy">Privacy Policy.</a> </p>

                        <div class="download_div">

                            <div>Mobile/Tablet</div>

                            <div class="app_ext">Android</div>

                            <a>Minimum Requirements (Version Android 11 or above)</a>

                            <a href="https://play.google.com/store/apps/details?id=callqr.com "> <div class="play_store_google_logo"></div></a>

                        </div>

                    </div>

                    <div class="comments_body">



                        <a class="management_title">

                            CallQR: Efficiently manage tokens, tickets, cashbooks, events, and more. Stay organized, save time, and boost earnings—securely and contactlessly!</a>

                    </div>

                </div>

           

                <div class="security-features">

                    <h2 class="security-title">Three Layer Security System</h2>

                    <ul class="feature-list">

                        <li><strong>Live Location Tracking:</strong> View the caller's real-time location to ensure their authenticity.</li>

                        <li><strong>Verified Users:</strong> Only verified users can connect with you, reducing the risk of spam and fraudulent calls.</li>

                        <li><strong>No Spam Calls:</strong> Advanced algorithms filter out spam and unwanted calls, giving you peace of mind.</li>

                    </ul>
                </div>
            </div>
        </div>
    );
};

export default DownloadPage;
