import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import homescannedalert from '../assets/homescannedalert.jpg';
import './Homedatawhoitwork.css';  // Import the CSS file
import Loginimg from '../assets/login.jpg';
const Homedatawhoitwork = () => {
  const [visible, setVisible] = useState(false);

  // Trigger animation immediately on component mount
  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div className="show-case-body">
      <motion.div
        className="animated-box"
        initial={{ opacity: 0, y: 50 }}
        animate={visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
      >
 <div className="container_size">
  {/* Title Section */}
  <div className="title-div">
    <div className="title-text">How It Works</div>
    <div className="desc-text">
      Unlocking Security: Discover How CallQR Stands Out
      <br />
    </div>
  </div>

  {/* Showcase Section */}
  <div className="show-case">
    {/* Left Content */}
    <div className="content">
      <h2>1. Get Started</h2>

      <p>
        Begin your journey with CallQR and enjoy peace of mind through our advanced Three-Layer Security System—designed to protect you at every step.
      </p>

      <ul>
        <li>
          <strong>1. Phone Verification:</strong> Confirm your mobile number to gain secure access to CallQR.
        </li>
        <li>
          <strong>2. Face Verification:</strong> Complete a quick and easy face verification in just a few minutes.
        </li>
        <li>
          <strong>3. Address Verification:</strong> Provide your name and address to unlock full access to CallQR’s trusted services.
        </li>
      </ul>

      <p>
        <strong>Your login is now complete. Welcome aboard! Enjoy secure access to all CallQR services.</strong>
      </p>
    </div>

    {/* Right Image */}
    <div className="image-container">
      <img
        src={Loginimg}
        alt="Scanned Alert"
        className="image"
      />
    </div>
  </div>
</div>

      </motion.div>
    </div>
  );
};

export default Homedatawhoitwork;
