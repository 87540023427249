import React, { useState } from 'react';
import { db } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
function EasyImageUploader() {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState('');
  const [url, setUrl] = useState('');

  const handleFileChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setFile(imageFile);
      setPreview(URL.createObjectURL(imageFile));
    }
  };

  const handleUpload = async () => {
    if (!file) return alert("Please select a file");
    const imageRef = ref(db, `uploads/${file.name}`);
    await uploadBytes(imageRef, file);
    const downloadURL = await getDownloadURL(imageRef);
    setUrl(downloadURL);
    alert("Upload Successful!");
  };

  return (
    <div style={{ padding: '20px' }}>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      {preview && <img src={preview} alt="Preview" width="200" />}
      <br />
      <button onClick={handleUpload}>Upload</button>
      {url && (
        <div>
          <p>Image URL:</p>
          <a href={url} target="_blank" rel="noreferrer">{url}</a>
        </div>
      )}
    </div>
  );
}

export default EasyImageUploader;
