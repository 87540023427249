import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet"; 
import './delete-account.css';

export default function Deleteaccount() {
  return (
    <div className="container_roots">
 <Helmet>
  <title>Delete Your CallQR Account | CallQR</title>
  <meta name="description" content="Need to delete your CallQR account? Follow the simple steps to remove your data and account permanently. Contact support if needed." />
  <meta name="robots" content="noindex, nofollow" />
  <meta name="author" content="callqr.com" />

  {/* Open Graph */}
  <meta property="og:title" content="Delete Your CallQR Account | CallQR" />
  <meta property="og:description" content="Learn how to delete your CallQR account safely. Follow step-by-step instructions to remove your account and personal data." />
  <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
  <meta property="og:url" content="https://callqr.com/delete-account" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CallQR" />

  {/* Twitter/X */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Delete Your CallQR Account | CallQR" />
  <meta name="twitter:description" content="Need help deleting your CallQR account? Get instructions to remove your account and data securely." />
  <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
  <meta name="twitter:site" content="@CALLQR_" />
  <meta name="twitter:url" content="https://x.com/CALLQR_" />

  {/* Mobile Optimization */}
  <meta name="theme-color" content="#000000" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="apple-mobile-web-app-title" content="CallQR" />
</Helmet>


      <div className="container_size" > 
        <div className="body_grid_size">
          <h2>How to Request Account Deletion</h2>
          <ol>
            <li><strong>Login to Your App Account:</strong></li>
            <li><strong>Navigate to Settings:</strong> Go to the <strong>Settings</strong> section.</li>
            <li><strong>Request Deletion:</strong> Click on the <strong>Delete My Account</strong> option and confirm your request by following the on-screen instructions.</li>
            <li>
              <strong>Alternative Method:</strong> If you are unable to access your account, you can request deletion by sending an email to 
              <a href="mailto:support@callqr.com"> support@callqr.com</a> with the subject line "Request for Account Deletion." 
              Please include the following details in your email:
              <ul>
                <li>Your registered email address.</li>
                <li>Your username (if applicable).</li>
                <li>Reason for account deletion (optional).</li>
              </ul>
            </li>
          </ol>

          <h2>What Happens After Deletion?</h2>
          <ul>
            <li>All your personal data, including phone number and usage history, will be permanently deleted from our servers within <strong>7 days</strong> of your request.</li>
            <li>Once deleted, your account cannot be recovered.</li>
            <li>Any linked services or subscriptions will be canceled.</li>
          </ul>

          <h2>Need Help?</h2>
          <p>
            If you have any questions or need assistance, please contact our support team at 
            <a href="mailto:support@callqr.com"> support@callqr.com</a>.
          </p>

          <p><em>Thank you for using CallQR.</em></p>
        </div>
      </div>
    </div>
  );
}
