import React from "react";
import { Helmet } from "react-helmet";
import { appRoutes } from "../routes";
import { Link } from "react-router-dom";
import './features.css';

export default function Sitemap() {

  const excludedPaths = ["/order","/orders", "/design", "/phonelogin", "/sitemap", "/settings"];

  const visibleRoutes = appRoutes.filter(route => !excludedPaths.includes(route.path));

  return (
    <div className="container_roots">
      <Helmet>
        <title>Sitemap | CallQR - Explore All QR Features & Pages</title>
        <meta
          name="description"
          content="Navigate through all CallQR pages including QR code generator, business QR codes, parking QR, app features, and more. Find what you need quickly with our sitemap."
        />
        <meta
          name="keywords"
          content="CallQR Sitemap, QR Code Generator Pages, QR Code App Navigation, Business QR, QR Pages, Parking QR Links, QR Tools Map"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="callqr.com" />

        {/* Open Graph */}
        <meta
          property="og:title"
          content="Sitemap | CallQR - Explore All QR Features & Pages"
        />
        <meta
          property="og:description"
          content="Quickly access all CallQR tools, QR features, and information through our sitemap. Start navigating now!"
        />
        <meta
          property="og:image"
          content="https://callqr.com/callqr_icon.png"
        />
        <meta property="og:url" content="https://callqr.com/sitemap" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CallQR" />

        {/* Twitter/X Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Sitemap | CallQR - Explore All QR Features & Pages"
        />
        <meta
          name="twitter:description"
          content="Easily explore CallQR tools and pages through our full sitemap. Find what you're looking for instantly."
        />
        <meta
          name="twitter:image"
          content="https://callqr.com/callqr_icon.png"
        />
        <meta name="twitter:site" content="@CALLQR_" />
        <meta name="twitter:url" content="https://x.com/CALLQR_" />

        {/* App & Mobile Support */}
        <meta name="google-play-app" content="app-id=callqr.com" />
        <meta name="theme-color" content="#000000" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="CallQR" />
      </Helmet>

      <div className="container_size">
  <h2 className="features-heading">Explore All Pages</h2>
  <p className="features-subtitle">Browse all the available pages of our website</p>

  <div className="features-grid">
    {visibleRoutes.map((route, index) => (
      <Link to={route.path} className="feature-card" key={index}>
        <div className="feature-icon">🔗</div> {/* optional icon */}
        <div className="feature-title">{route.name}</div>
        <div className="feature-desc">{route.description || 'Click to explore this page.'}</div>
      </Link>
    ))}
  </div>
</div>
    </div>
  );
}
