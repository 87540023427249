import React from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import { Helmet } from "react-helmet";
import "./help-center.css";

export default function Helpcenter() {
  return (
    <div className="help-center-root">
      <Helmet>
        <title>Help Center | CallQR Support & FAQ</title>
        <meta name="description" content="Need help using CallQR? Visit our Help Center for FAQs, QR code troubleshooting, app usage guides, and support. Get instant answers and expert assistance." />
        <meta name="keywords" content="CallQR Help, QR Code Support, CallQR FAQ, QR Generator Help, CallQR Troubleshooting, App Guide, QR Code Issues, Support Center, CallQR Contact" />
        <meta name="author" content="callqr.com" />
        <meta property="og:title" content="Help Center | CallQR Support & FAQ" />
        <meta property="og:description" content="Find answers to your questions, troubleshoot QR issues, and get help using the CallQR app in our Help Center." />
        <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/help-center" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CallQR" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Help Center | CallQR Support & FAQ" />
        <meta name="twitter:description" content="Need help with QR codes? Explore CallQR’s Help Center for answers, troubleshooting, and support." />
        <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
        <meta name="twitter:site" content="@CALLQR_" />
        <meta name="twitter:url" content="https://x.com/CALLQR_" />
        <meta name="google-play-app" content="app-id=callqr.com" />
        <meta name="theme-color" content="#000000" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="CallQR" />
      </Helmet>

      <div className="help-center-container">
        <h1 className="help-title">Welcome to the CallQR Help Center</h1>
        <p className="help-subtitle">Find quick answers, contact support, and learn how to get the most from CallQR.</p>

        <div className="help-grid">
          <div className="help-card">
            <h2>📄 FAQs</h2>
            <p>Get answers to the most commonly asked questions about QR code generation and scanning.</p>
            <a href="/faq" className="help-link">Visit FAQ</a>
          </div>

          <div className="help-card">
            <h2>🛠 Troubleshooting</h2>
            <p>Having issues with QR code visibility or scanning? Check out our troubleshooting guide.</p>
            <a href="/troubleshoot" className="help-link">Fix an Issue</a>
          </div>

          <div className="help-card">
            <h2>📱 App Usage</h2>
            <p>Learn how to use the CallQR app, customize QR codes, and track scans easily.</p>
            <a href="/user_guide" className="help-link">See App Guide</a>
          </div>
          <div className="help-card">
            <h2>🗺️ Sitemap</h2>
            <p>Quickly navigate through all pages and features of the CallQR website using our organized sitemap.</p>
            <a href="/sitemap" className="help-link">Explore Sitemap</a>
          </div>
          <div className="help-card contact-card">
            <h2>📞 Contact Us</h2>
            <p>Need more help? Contact our support team.</p>
            <div className="contact-icons">
              <a href="tel:+919530621728"><FaPhone /> +919530621728</a>
              <a href="mailto:support@callqr.com"><FaEnvelope /> support@callqr.com</a>
            </div>
          </div>
         {/* Technology Support Card */}
         <div className="help-card contact-card">
            <h2>💻 Technology Support</h2>
            <p>For technical assistance, system integration, or API support, reach out to our tech team.</p>
            <div className="contact-icons">
              <a href="tel:+918872888728"><FaPhone /> +918872888728</a>
              <a href="mailto:tech@callqr.com"><FaEnvelope /> Rupinder@callqr.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
