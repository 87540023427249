import React, { useState } from "react";
import Select from "react-select";

const countryPhoneCodes = [
    { label: "Afghanistan (+93)", value: "+93" },
    { label: "Albania (+355)", value: "+355" },
    { label: "Algeria (+213)", value: "+213" },
    { label: "Andorra (+376)", value: "+376" },
    { label: "Angola (+244)", value: "+244" },
    { label: "Antigua and Barbuda (+1-268)", value: "+1-268" },
    { label: "Argentina (+54)", value: "+54" },
    { label: "Armenia (+374)", value: "+374" },
    { label: "Australia (+61)", value: "+61" },
    { label: "Austria (+43)", value: "+43" },
    { label: "Azerbaijan (+994)", value: "+994" },
    { label: "Bahamas (+1-242)", value: "+1-242" },
    { label: "Bahrain (+973)", value: "+973" },
    { label: "Bangladesh (+880)", value: "+880" },
    { label: "Barbados (+1-246)", value: "+1-246" },
    { label: "Belarus (+375)", value: "+375" },
    { label: "Belgium (+32)", value: "+32" },
    { label: "Belize (+501)", value: "+501" },
    { label: "Benin (+229)", value: "+229" },
    { label: "Bhutan (+975)", value: "+975" },
    { label: "Bolivia (+591)", value: "+591" },
    { label: "Bosnia and Herzegovina (+387)", value: "+387" },
    { label: "Botswana (+267)", value: "+267" },
    { label: "Brazil (+55)", value: "+55" },
    { label: "Brunei (+673)", value: "+673" },
    { label: "Bulgaria (+359)", value: "+359" },
    { label: "Burkina Faso (+226)", value: "+226" },
    { label: "Burundi (+257)", value: "+257" },
    { label: "Cambodia (+855)", value: "+855" },
    { label: "Cameroon (+237)", value: "+237" },
    { label: "Canada (+1)", value: "+1" },
    { label: "Cape Verde (+238)", value: "+238" },
    { label: "Central African Republic (+236)", value: "+236" },
    { label: "Chad (+235)", value: "+235" },
    { label: "Chile (+56)", value: "+56" },
    { label: "China (+86)", value: "+86" },
    { label: "Colombia (+57)", value: "+57" },
    { label: "Comoros (+269)", value: "+269" },
    { label: "Congo (+242)", value: "+242" },
    { label: "Costa Rica (+506)", value: "+506" },
    { label: "Croatia (+385)", value: "+385" },
    { label: "Cuba (+53)", value: "+53" },
    { label: "Cyprus (+357)", value: "+357" },
    { label: "Czech Republic (+420)", value: "+420" },
    { label: "Denmark (+45)", value: "+45" },
    { label: "Djibouti (+253)", value: "+253" },
    { label: "Dominican Republic (+1-809)", value: "+1-809" },
    { label: "Ecuador (+593)", value: "+593" },
    { label: "Egypt (+20)", value: "+20" },
    { label: "El Salvador (+503)", value: "+503" },
    { label: "Estonia (+372)", value: "+372" },
    { label: "Eswatini (+268)", value: "+268" },
    { label: "Ethiopia (+251)", value: "+251" },
    { label: "Fiji (+679)", value: "+679" },
    { label: "Finland (+358)", value: "+358" },
    { label: "France (+33)", value: "+33" },
    { label: "Germany (+49)", value: "+49" },
    { label: "Ghana (+233)", value: "+233" },
    { label: "Greece (+30)", value: "+30" },
    { label: "Guatemala (+502)", value: "+502" },
    { label: "Honduras (+504)", value: "+504" },
    { label: "Hungary (+36)", value: "+36" },
    { label: "Iceland (+354)", value: "+354" },
    { label: "India (+91)", value: "+91" },
    { label: "Indonesia (+62)", value: "+62" },
    { label: "Iran (+98)", value: "+98" },
    { label: "Iraq (+964)", value: "+964" },
    { label: "Ireland (+353)", value: "+353" },
    { label: "Israel (+972)", value: "+972" },
    { label: "Italy (+39)", value: "+39" },
    { label: "Jamaica (+1-876)", value: "+1-876" },
    { label: "Japan (+81)", value: "+81" },
    { label: "Jordan (+962)", value: "+962" },
    { label: "Kazakhstan (+7)", value: "+7" },
    { label: "Kenya (+254)", value: "+254" },
    { label: "Kuwait (+965)", value: "+965" },
    { label: "Malaysia (+60)", value: "+60" },
    { label: "Mexico (+52)", value: "+52" },
    { label: "Morocco (+212)", value: "+212" },
    { label: "Nepal (+977)", value: "+977" },
    { label: "Netherlands (+31)", value: "+31" },
    { label: "New Zealand (+64)", value: "+64" },
    { label: "Nigeria (+234)", value: "+234" },
    { label: "Pakistan (+92)", value: "+92" },
    { label: "Philippines (+63)", value: "+63" },
    { label: "Poland (+48)", value: "+48" },
    { label: "Russia (+7)", value: "+7" },
    { label: "Saudi Arabia (+966)", value: "+966" },
    { label: "South Africa (+27)", value: "+27" },
    { label: "South Korea (+82)", value: "+82" },
    { label: "Spain (+34)", value: "+34" },
    { label: "Sri Lanka (+94)", value: "+94" },
    { label: "Sweden (+46)", value: "+46" },
    { label: "Switzerland (+41)", value: "+41" },
    { label: "Thailand (+66)", value: "+66" },
    { label: "Turkey (+90)", value: "+90" },
    { label: "United Kingdom (+44)", value: "+44" },
    { label: "United States (+1)", value: "+1" },
    { label: "Vietnam (+84)", value: "+84" }
];

const PhoneCodeList = ({ onChange }) => {
    const [phoneCodes] = useState(countryPhoneCodes);

    return (
        <Select 
            className="codelist-input" 
            options={phoneCodes} 
            placeholder="Select country code"
            onChange={onChange}  // Ensure this is passed
        />
    );
};

export default PhoneCodeList;
