import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet"; 
import './tearms.css';
export default function Disclaimer() {
  return (
    <div className="container_roots">
<Helmet>
  <title>Disclaimer | CallQR</title>
  <meta name="description" content="Read the official disclaimer of CallQR regarding the usage, liability, and accuracy of information on our QR code generator platform." />
  <meta name="keywords" content="CallQR Disclaimer, QR Code Terms, QR Code Generator Disclaimer, Liability Notice, Information Accuracy, CallQR Terms" />
  <meta name="robots" content="noindex, nofollow" />
  <meta name="author" content="callqr.com" />

  {/* Open Graph */}
  <meta property="og:title" content="Disclaimer | CallQR" />
  <meta property="og:description" content="Understand the legal disclaimers related to using the CallQR QR code platform. Read our official notice." />
  <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
  <meta property="og:url" content="https://callqr.com/disclaimer" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CallQR" />

  {/* Twitter/X */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Disclaimer | CallQR" />
  <meta name="twitter:description" content="Learn more about our disclaimer and the terms under which CallQR operates." />
  <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
  <meta name="twitter:site" content="@CALLQR_" />
  <meta name="twitter:url" content="https://x.com/CALLQR_" />

  {/* Optional - Prevent indexing if it's a legal notice page */}
  <meta name="google-play-app" content="app-id=callqr.com" />
</Helmet>

      <div className="container_size">
      <h1 className="contact-title">Disclaimer</h1>
      <p>This disclaimer ("Disclaimer") sets forth the general guidelines, disclosures, and terms of your use of the
        CallQR.com website ("Website" or "Service") and any of its related products and services (collectively, "Services").</p>

    <h2>General Information</h2>
    <p>The information provided by CallQR ("we," "us," or "our") on the Website is for general informational purposes only.
        All information on the Website is provided in good faith; however, we make no representation or warranty of any kind,
        express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Website.</p>

    <h2>Limitation of Liability</h2>
    <p>Under no circumstance shall CallQR be liable for any damages incurred as a result of the use of the Website or
        reliance on any information provided on the Website. Your use of the Website and your reliance on any information
        on the Website is solely at your own risk.</p>

    <h2>External Links Disclaimer</h2>
    <p>The Website may contain or you may be sent through the Website to links to other websites or content belonging to or
        originating from third parties or links to websites and features in banners or other advertising. Such external links
        are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness
        by us.</p>

    <p><span class="notice">We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of
        any information offered by third-party websites linked through the Website or any website or feature linked in any banner
        or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you
        and third-party providers of products or services.</span></p>

    <h2>Affiliates Disclaimer</h2>
    <p>CallQR may have financial relationships with some of the merchants mentioned on the Website. We may be compensated
        if you click on links that are on the Website and make a purchase from such merchants. CallQR is a participant in various
        affiliate advertising programs designed to provide a means for us to earn fees by linking to affiliated sites.</p>

    <h2>Changes and Amendments</h2>
    <p>We reserve the right to modify this Disclaimer or its terms related to the Website or Services at any time, effective upon
        posting of an updated version of this Disclaimer on the Website. When we do, we will revise the updated date at the bottom
        of this page. Continued use of the Website after any such changes shall constitute your consent to such changes.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions about this Disclaimer, please contact us at <a href="mailto:support@callqr.com">support@callqr.com</a>.</p>

    <p><strong>Last Updated:</strong> 01 April 2025</p>
      </div>
</div>
    );
}
