import './App.css';
import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { appRoutes } from './routes';
import Cookies from "js-cookie";
import Header from './body_roots/header';
import Footer from './body_roots/footer';
import Home from './home_pages/home'; 
import WhatsAppQueryPopup from './components/WhatsAppQueryPopup'; 
function App() {
  const [location, setLocation] = useState({
    lat: null,
    lon: null,
    address: "Fetching...",
    city: "",
    state: "",
    country: "",
    zipcode: ""
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      const geoWatcher = navigator.geolocation.watchPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          // Fetch address details from Google Geocoding API
          const apiKey = "AIzaSyBBrDds_05fvxejfzmm5gm4ywDQIlEO6E4"; // 🔴 Replace with your Google Maps API Key
          const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

          try {
            const response = await fetch(geocodeUrl);
            const data = await response.json();

            if (data.results.length > 0) {
              const addressComponents = data.results[0].address_components;
              const formattedAddress = data.results[0].formatted_address;

              let city = "";
              let state = "";
              let country = "";
              let zipcode = "";

              addressComponents.forEach((component) => {
                if (component.types.includes("locality")) city = component.long_name;
                if (component.types.includes("administrative_area_level_1")) state = component.long_name;
                if (component.types.includes("country")) country = component.long_name;
                if (component.types.includes("postal_code")) zipcode = component.long_name;
              });

              const addressData = {
                lat: latitude,
                lon: longitude,
                address: formattedAddress,
                city,
                state,
                country,
                zipcode
              };

              // Update state
              setLocation(addressData);

              // Store in cookies
              Cookies.set("lat", latitude);
              Cookies.set("lon", longitude);
              Cookies.set("address", formattedAddress);
              Cookies.set("city", city);
              Cookies.set("state", state);
              Cookies.set("country", country);
              Cookies.set("zipcode", zipcode);
            }
          } catch (error) {
            console.error("Error fetching address:", error);
          }
        },
        (error) => console.error("Error getting location:", error),
        { enableHighAccuracy: true, maximumAge: 0 }
      );

      return () => navigator.geolocation.clearWatch(geoWatcher);
    } else {
      console.error("Geolocation is not supported.");
    }
  }, []);

 
  return (
    <div className="App">
      <Header />
      <WhatsAppQueryPopup />
      <div className='app_header_height'></div>
      <Routes>
  {appRoutes.map(({ path, element }, i) => (
    <Route key={i} path={path} element={element} />
  ))}
  <Route path="/:id" element={<Home />} />
</Routes>
      <Footer />
    </div>
  );
}

export default App;
