import React from 'react';
import './about-us.css';

const AboutUs = () => {
  return (
    <div className="container_roots">
         <div className="container_size">
    <div className="about-page">
      <section className="about-hero">
        <h1>About CallQR</h1>
        <p>Innovating QR solutions for businesses, events, and digital experiences.</p>
      </section>

      <section className="about-grid">
        <div className="about-card">
          <h2>Who We Are</h2>
          <p>
            CallQR is a tech-forward company that simplifies digital access by creating
            smart, branded QR solutions. We help you bridge physical and digital worlds.
          </p>
        </div>

        <div className="about-card">
          <h2>What We Do</h2>
          <p>
            We design and deliver ready-to-use QR codes tailored for your business needs,
            such as menus, payments, digital visiting cards, parking systems, and more.
          </p>
        </div>

        <div className="about-card">
          <h2>Why Choose Us?</h2>
          <ul>
            <li>⚡ Instant QR Generation</li>
            <li>🎨 Fully Customizable</li>
            <li>🛡️ Secure & Scalable</li>
            <li>🚀 Easy Integration</li>
          </ul>
        </div>

        <div className="about-card">
          <h2>Contact & Support</h2>
          <p>
            Need help or have a question? <br />
            Reach us at <a href="mailto:support@callqr.com">support@callqr.com</a>
          </p>
        </div>
      </section>
 
    </div>
    </div>
    </div>
    
  );
};

export default AboutUs;
