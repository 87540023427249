import React from "react";
import { Helmet } from "react-helmet";
import { FaQrcode, FaChartLine, FaPaintBrush, FaShieldAlt, FaMobileAlt } from "react-icons/fa";
import "./features.css"; // Import the CSS file

const featuresList = [
  {
    title: "Custom QR Codes",
    icon: <FaQrcode />,
    description: "Personalize your QR codes with logos, colors, and patterns for better brand identity.",
  },
  {
    title: "Analytics & Tracking",
    icon: <FaChartLine />,
    description: "Track scan activity with powerful analytics tools in real-time.",
  },
  {
    title: "Design Flexibility",
    icon: <FaPaintBrush />,
    description: "Choose from borders, shapes, and effects to style your QR code creatively.",
  },
  {
    title: "Enterprise Security",
    icon: <FaShieldAlt />,
    description: "Data protection through advanced security protocols and encryption.",
  },
  {
    title: "Mobile Friendly",
    icon: <FaMobileAlt />,
    description: "Generate and scan codes seamlessly across all devices.",
  },
];

export default function Features() {
  return (
    <div className="container_roots">
    <div className="container_size">
    <div className="features-wrapper">
    <Helmet>
  <title>QR Features | CallQR - Customize, Track & Secure Your QR Codes</title>
  <meta name="description" content="Explore powerful features of CallQR including custom QR code design, analytics, branding options, enterprise security, and mobile-friendly performance." />
  <meta name="keywords" content="QR code features, CallQR tools, QR customization, QR tracking, secure QR code, branded QR, dynamic QR codes" />
  <meta name="robots" content="index, follow" />
  <meta name="author" content="callqr.com" />

  {/* Open Graph / Facebook */}
  <meta property="og:title" content="QR Features | CallQR - Customize, Track & Secure Your QR Codes" />
  <meta property="og:description" content="Discover all the advanced features of CallQR — from custom design to real-time scan tracking and high-grade security." />
  <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
  <meta property="og:url" content="https://callqr.com/features" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CallQR" />

  {/* Twitter */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="QR Features | CallQR - Customize, Track & Secure Your QR Codes" />
  <meta name="twitter:description" content="Explore CallQR's powerful features like real-time QR scan analytics, branding tools, and secure code generation." />
  <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
  <meta name="twitter:site" content="@CALLQR_" />
  <meta name="twitter:url" content="https://x.com/CALLQR_" />

  {/* App/Mobile Optimizations */}
  <meta name="theme-color" content="#0f172a" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="apple-mobile-web-app-title" content="CallQR" />
</Helmet>

<div>
      <h1 className="features-heading">Our QR Features</h1>
      <div className="features-subtitle">Powerful tools to make your QR experience smart and secure</div>
      </div>
      <div className="features-grid">
        {featuresList.map((feature, index) => (
          <div className="feature-card" key={index}>
            <div className="feature-icon">{feature.icon}</div>
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-desc">{feature.description}</p>
          </div>
        ))}
      </div>
      </div>
      </div>
    </div>
  );
}
