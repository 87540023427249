import React, { useState } from 'react';
import { db } from "../firebase"; 
import { getFirestore, doc, setDoc, getDoc, updateDoc, collection } from "firebase/firestore";
import Lodinging from "./lodinging"; 
const WhatsAppQueryPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
 const [loading, setLoading] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const sendWhatsApp = async () => {
    if (!name || !phone || !message ) {
 
      return;
    }
    setLoading(true);
    try {
        function generateRandomString(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
          }
        // Save to Firestore
        const uid = generateRandomString(16);  
     
        const query = doc(db, "query", uid);

        await setDoc(query, {
            uid: uid,
            phone: phone,
            name: name,
            category:"Whatsapp",
            message: message,                   
            status: true, 
            date_time: new Date()
        }); 
    
    } catch (ex) {
        console.error("An error occurred:", ex);
        setLoading(false);
    } 
    
    const text = encodeURIComponent(
      `https://callqr.com Query:\nName: ${name}\nPhone: ${phone}\nMessage: ${message}`
    );
   
    const whatsappNumber = '919530621728'; // Replace with your number
    const url = `https://wa.me/${whatsappNumber}?text=${text}`;

    setLoading(false);
    togglePopup();
    window.open(url, '_blank');
  };

  return (
    <div>
      {/* Floating Button */}
      <button id='WhatsAppQueryPopup'
        onClick={togglePopup}
         className='whatsapp-button'
      >
        💬 Chat with us
      </button>

      {/* Popup */}
      {showPopup && (
        <div
          style={{
            position: 'fixed',
            bottom: '80px',
            right: '20px',
            width: '300px',
            background: '#fff',
            borderRadius: '15px',
            boxShadow: '0 0 20px rgba(0,0,0,0.1)',
            padding: '20px',
            zIndex: 1000,
          }}
        >
          <h3 style={{ textAlign: 'center', color: '#075e54', margin: 0 }}>What's Query</h3>
          <input
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={inputStyle}
          />
          <input
            type="phone"
            placeholder="Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={inputStyle}
          />
          <textarea
            rows="3"
            placeholder="Your Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={inputStyle}
          />
          <button onClick={sendWhatsApp} style={sendBtnStyle}>
          {loading ? <Lodinging/> : "Send Query"}</button>

          <button onClick={togglePopup} style={closeBtnStyle}>Close</button>
        </div>
      )}
    </div>
  );
};

const inputStyle = {
  width: 'calc(100% - 20px)',
  marginTop: '10px',
  padding: '10px',
  fontSize: '14px',
  borderRadius: '8px',
  border: '1px solid #ccc',
  textTransform:'capitalize'
};

const sendBtnStyle = {
  marginTop: '10px',
  backgroundColor: '#25D366',
  color: 'white',
  border: 'none',
  padding: '5px',
  height: '40px',
  width: '100%',
  fontSize: '15px',
  borderRadius: '8px',
  cursor: 'pointer',
};

const closeBtnStyle = {
  marginTop: '10px',
  backgroundColor: '#ccc',
  color: '#000',
  border: 'none',
  padding: '10px',
  width: '100%',
  fontSize: '15px',
  borderRadius: '8px',
  cursor: 'pointer',
};

export default WhatsAppQueryPopup;
