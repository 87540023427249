import React from 'react';
import './ButtonWithImage.css';
import { GrFormNextLink } from "react-icons/gr";

const Buttontheme = ({ 
  title = "Boost Your Productivity", 
  buttonText = "Get Started",  
  onClick 
}) => {
  return (
    <div 
      className="custom-button" 
      title={title} 
      onClick={onClick}
      style={{ cursor: "pointer" }} 
    >
      <span className="button-text">{buttonText}
      </span>
      <GrFormNextLink className="icon button-icon" />
    </div>
  );
};

export default Buttontheme;
