import React from "react";
import { Helmet } from "react-helmet";
import "./Careers.css";
export default function Careers() {
  return (
    <div className="container_roots">
<Helmet>
  <title>Careers at CallQR | Join Our Growing Tech Team</title>
  <meta name="description" content="Explore exciting career opportunities at CallQR. Join our mission to simplify QR technology. We’re hiring developers, designers, and marketers!" />
  <meta name="keywords" content="CallQR Careers, Work at CallQR, Tech Jobs, QR Code Company Jobs, Developer Jobs, Marketing Jobs, Join CallQR Team, Hiring at CallQR" />
  <meta name="robots" content="index, follow" />
  <meta name="author" content="callqr.com" />

  {/* Open Graph (Facebook, LinkedIn) */}
  <meta property="og:title" content="Careers at CallQR | Join Our Growing Tech Team" />
  <meta property="og:description" content="Explore exciting opportunities at CallQR. Join our mission to simplify QR technology and grow with us." />
  <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
  <meta property="og:url" content="https://callqr.com/careers" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="CallQR" />

  {/* Twitter/X Card */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Careers at CallQR | Join Our Growing Tech Team" />
  <meta name="twitter:description" content="We’re hiring! Find open positions at CallQR and be a part of revolutionizing QR technology." />
  <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
  <meta name="twitter:site" content="@CALLQR_" />
  <meta name="twitter:url" content="https://x.com/CALLQR_" />

  {/* Mobile/Web App Tags */}
  <meta name="theme-color" content="#000000" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="apple-mobile-web-app-title" content="CallQR" />
</Helmet>


      <div className="container_size">
        <h1>Explore career opportunities at CallQR and be part of a growing team driving QR technology forward.</h1>

        <section className="section">
          <h2>Career Opportunities at CallQR</h2>
          <p>
            Flexible work options that suit your preferences and lifestyle. Whether you are looking for a salary-based job, a target-based role, or an opportunity to earn sales incentives, we have something for you!
          </p>
          <h3>Why Work with Us?</h3>
          <ul>
            <li><strong>Flexible Work Locations:</strong> Work in your local area and connect with customers conveniently.</li>
            <li><strong>Attractive Compensation:</strong> Choose between a fixed salary model or a target-based incentive structure.</li>
            <li><strong>Sales Incentives:</strong> Boost your earnings with performance-based rewards for achieving sales targets.</li>
            <li><strong>Growth Opportunities:</strong> Develop your skills and grow with a company that values its employees.</li>
          </ul>
        </section>

        <section className="section">
          <h2>Who Are We Looking For?</h2>
          <p>We are looking for passionate individuals who:</p>
          <ul>
            <li>Are self-motivated and target-driven.</li>
            <li>Have excellent communication and interpersonal skills.</li>
            <li>Are eager to work in sales and customer engagement.</li>           
            <li><strong>Minimum Education Qualification:</strong> Must have completed 12th grade (High School).</li>
          </ul>
        </section>

        <section className="section">
          <h2>How to Apply</h2>
          <p>
            If you are ready to take the next step in your career with CallQR, send your resume to{" "}
            <a href="mailto:support@callqr.com">support@callqr.com</a>. Join CallQR and make an impact in your local area!
          </p>
        </section>

        <div className="cta">
          <a href="mailto:support@callqr.com">Apply Now</a>
        </div>
      </div>
    </div>
  );
}
