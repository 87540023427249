import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet"; 
import './tearms.css';
export default function Termsofservice() {
  return (
    <div className="container_roots">
  
  <Helmet>
  <title>Terms of Service | CallQR</title>
  <meta name="description" content="Read the Terms of Service for using CallQR. Understand our policies on QR code generation, privacy, security, user responsibilities, and limitations of liability." />
  <meta name="keywords" content="CallQR Terms of Service, QR Code Policy, QR Code Terms, CallQR Legal, QR Code Generator Agreement, QR User Policy, CallQR App Terms" />
  <meta name="robots" content="index, follow" />
  <meta name="author" content="callqr.com" />

  {/* Open Graph */}
  <meta property="og:title" content="Terms of Service | CallQR" />
  <meta property="og:description" content="Review the official Terms of Service for using CallQR's QR code generator and scanner." />
  <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
  <meta property="og:url" content="https://callqr.com/terms-of-service" />
  <meta property="og:type" content="article" />
  <meta property="og:site_name" content="CallQR" />

  {/* Twitter/X Card */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Terms of Service | CallQR" />
  <meta name="twitter:description" content="Understand your rights and responsibilities when using CallQR's services. View our Terms of Service." />
  <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
  <meta name="twitter:site" content="@CALLQR_" />
  <meta name="twitter:url" content="https://x.com/CALLQR_" />

  {/* App linking */}
  <meta name="google-play-app" content="app-id=callqr.com" />

  {/* Mobile settings */}
  <meta name="theme-color" content="#000000" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="apple-mobile-web-app-title" content="CallQR" />
</Helmet>

      <div className="container_size">
      <h1 className="contact-title">Terms of service</h1>
      <p><strong>Last Updated:</strong> 01 April 2025</p>
    
    <h2>1. Introduction</h2>
    <p>Welcome to <strong>Callqr.com</strong>. By using our app, you agree to comply with and be bound by these Terms of Service.</p>

    <h2>2. User Agreement</h2>
    <p>By accessing or using the app, you confirm that you are at least 13 years old and agree to abide by these terms.</p>

    <h2>3. Use of the App</h2>
    <p>You agree to use this app only for lawful purposes and not to engage in activities that violate any laws or regulations.</p>

    <h2>4. Privacy Policy</h2>
    <p>Your use of the app is also governed by our <a href="privacy-policy">Privacy Policy</a>.</p>

    <h2>5. Intellectual Property</h2>
    <p>All content, trademarks, and logos used in this app are the property of <strong>[Your Company Name]</strong> and may not be used without permission.</p>

    <h2>6. Prohibited Activities</h2>
    <p>You are prohibited from:</p>
    <ul>
        <li>Hacking, reverse engineering, or modifying the app.</li>
        <li>Using the app for illegal activities.</li>
        <li>Transmitting harmful code such as viruses.</li>
    </ul>

    <h2>7. Limitation of Liability</h2>
    <p>We are not responsible for any damages or losses resulting from your use of the app.</p>

    <h2>8. Changes to Terms</h2>
    <p>We reserve the right to modify these Terms of Service at any time. Continued use of the app means you accept the updated terms.</p>

    <h2>9. Termination</h2>
    <p>We may terminate or suspend your access to the app if you violate these terms.</p>

    <h2>10. Contact Us</h2>
    <p>If you have any questions, contact us at:</p>
    <p><strong>Email:</strong> support@callqr.com</p>
    <p><strong>Website:</strong> <a href="https://callqr.com/" target="_blank">https://Callqr.com</a></p>

      </div>
</div>
    );
}
